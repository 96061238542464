const initialState = [];

const folderReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'FOLDER_FETCH':
      return [
        ...state,
        {
          folderId: action.folderId,
          fetching: true,
          fetched: false,
          data: {},
        },
      ];

    case 'FOLDER_FETCH_SUCCESS':
      return state.map((folder) => {
        if (folder.folderId === action.meta.previousAction.folderId) {
          return {
            ...folder,
            fetching: false,
            fetched: true,
            data: action.payload.data,
          };
        }
        return folder;
      });

    case 'FOLDER_FETCH_ERROR':
      return state.map((folder) => {
        if (folder.folderId === action.meta.previousAction.folderId) {
          return {
            ...folder,
            fetching: false,
            fetched: false,
            error: {
              message: action.error.message,
              statusCode: action.error.response.status,
            },
          };
        }
        return folder;
      });
    default:
      return state;
  }
};

export default folderReducers;
