import { takeEvery, select, put } from 'redux-saga/effects';
import { postStatistics } from '../actions';

function* statisticsSend(action) {
  const sessionID = yield select((state) => state.session.sessionID);

  const siteId = document.querySelector('#connect-injector')?.getAttribute('data-site-id')
  || window.location.host;

  const siteName = document.querySelector('#connect-injector')?.getAttribute('data-site-name')
  || process.env.REACT_APP_SITE_NAME;

  const pageId = document.querySelector('#connect-injector')?.getAttribute('data-page-id');

  const windowWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

  const windowHeight = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

  const localStorageReferrer = localStorage.getItem('orgIndexlocalReferrer') || '';

  // add the metadata we want
  const statistics = {
    ...action.data,
    application: 'connect',
    client: {
      window_size: `${windowWidth};${windowHeight}`,
      session: sessionID,
    },
    context: {
      site_name: siteName,
      site_id: siteId,
      id: pageId,
      url: document.URL.replace(/\/$/, ''), // remove trailing slash
      title: document.title,
      referer: localStorageReferrer !== '' ? localStorageReferrer : document.referrer,
      ...action.data.context,
    },
  };

  // POST it
  yield put(postStatistics(statistics));
}

// grab every sendStatistics action
const statisticsSagas = [
  takeEvery('STATISTICS_SEND', statisticsSend),
];

export default statisticsSagas;
