import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';

const initialState = {
  data: {
    startpage: null,
    commonLinks: [],
    preferredLocalIntranet: null,
    favoriteSites: [],
  },
  opened: false,
  fetching: false,
  fetched: false,
  posting: false,
  error: null,
};

const headerLinks = (state = initialState, action) => {
  switch (action.type) {
    case 'HEADERLINKS_FETCH':
      return { ...state, fetching: true };

    case 'HEADERLINKS_FETCH_SUCCESS':
      return {
        ...state, fetching: false, fetched: true, data: action.payload.data,
      };
    case 'HEADERLINKS_FETCH_ERROR':
      return { ...state, fetching: false, error: action.error };
    case 'HEADERLINKS_POST':
      return {
        ...state, posting: true,
      };
    case 'HEADERLINKS_POST_SUCCESS':
      return {
        ...state,
        posting: false,
        data: {
          ...state.data,
          favoriteSites: [...state.data.favoriteSites, action.payload.data[0]],
        },
      };
    case 'HEADERLINKS_POST_ERROR':
      return { ...state, posting: false, error: action.error };
    case 'HEADERLINKS_DELETE':
      return {
        ...state, posting: true,
      };
    case 'HEADERLINKS_DELETE_SUCCESS':
      return {
        ...state,
        posting: false,
        data: {
          ...state.data,
          favoriteSites: state.data.favoriteSites
            .filter((item) => item.site_id !== action.payload.data.siteId),
        },
      };
    case 'HEADERLINKS_DELETE_ERROR':
      return { ...state, posting: false, error: action.error };
    case 'HEADERLINKS_UPDATE_PREFERREDLOCALINTRANET':
      return {
        ...state,
        data: { ...state.data, preferredLocalIntranet: action.payload.preferredLocalIntranet },
      };
    default:
      return { ...state };
  }
};

const persistConfig = {
  key: 'headerLinks',
  storage: storageSession,
  whitelist: ['data', 'fetched'],
};

export default persistReducer(persistConfig, headerLinks);
