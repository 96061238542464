import { takeLeading, put, select } from 'redux-saga/effects';
import { fetchTypeahead } from './actions';

function* typeaheadSaga() {
  const isFetched = yield select((state) => state.typeahead.fetched);
  const isFetching = yield select((state) => state.typeahead.fetching);
  if (!isFetched && !isFetching) {
    yield put(fetchTypeahead());
  }
}

const typeaheadSagas = [
  takeLeading('TYPEAHEAD_REQUEST', typeaheadSaga),
];

export default typeaheadSagas;
