import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
};

const campaignComponentsReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'CAMPAIGN_COMPONENTS_REQUEST':
      return {
        ...state,
      };
    case 'CAMPAIGN_COMPONENTS_FETCH':
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    case 'CAMPAIGN_COMPONENTS_FETCH_SUCCESS':
      return {
        ...state,
        error: false,
        data: action.payload.data,
        timestamp: +new Date(),
        fetching: false,
        fetched: true,
      };
    case 'CAMPAIGN_COMPONENTS_FETCH_ERROR':
      return {
        ...state,
        error: action.error,
        fetching: false,
        fetched: true,
      };
    default:
      return state;
  }
};
const campaignComponentsConfig = {
  key: 'campaignComponents',
  storage: storageSession,
  whitelist: ['data', 'fetched', 'timestamp'],
};

export default persistReducer(campaignComponentsConfig, campaignComponentsReducers);
