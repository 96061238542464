import storageSession from 'redux-persist/lib/storage/session';
import {
  createMigrate, persistReducer,
} from 'redux-persist';
import shortid from 'shortid';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

const initialState = {
  data: {
    url: null,
    thumbnail: null,
    title: null,
    reflex_terms_of_use_date: [],
    reflex_cookie_date: null,
  },
  avatar: null,
  fetching: false,
  fetched: false,
  error: null,
  sessionID: null,
  posting: false,
  posted: false,
  previousData: null,
};

const session = (state = initialState, action) => {
  switch (action.type) {
    case 'SESSION_FETCH':
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    case 'SESSION_FETCH_SUCCESS':
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data,
        sessionID: shortid.generate(),
        avatar: state.avatar,
      };
    case 'SESSION_PUT':
      return {
        ...state,
        posting: true,
        posted: false,
        data: {
          ...state.data,
          ...action.payload.request.data,
        },
        previousData: {
          ...state.data,
        },
      };
    case 'SESSION_PUT_SUCCESS':
      return {
        ...state,
        posting: false,
        posted: true,
        error: null,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };

    case 'SESSION_PUT_ERROR':
      return {
        ...state,
        posting: false,
        error: action.error,
        data: {
          ...state.previousData,
        },
      };

    case 'AVATAR_POST':
      return {
        ...state,
        posted: false,
        posting: true,
        avatar: action.avatar,
      };
    case 'AVATAR_POST_SUCCESS':
      return {
        ...state,
        posting: false,
        posted: true,
        data: {
          ...state.data,
        },
        avatar: state.avatar,
      };
    case 'AVATAR_POST_ERROR':
      return {
        ...state,
        posting: false,
        avatar: null,
      };

    case 'AVATAR_DELETE':
      return {
        ...state,
        posted: false,
        posting: true,
      };

    case 'AVATAR_DELETE_SUCCESS':
      return {
        ...state,
        posting: false,
        posted: true,
        data: {
          ...state.data,
        },
        avatar: action.payload.data.thumbnail,
      };

    case 'AVATAR_DELETE_ERROR':
      return {
        ...state,
        posting: false,
      };

    case 'PUT_SESSION_INTRA':
      return {
        ...state,
        posting: true,
        posted: false,
        previousPreferredLocalIntranetURL: state.data.preferred_local_intranet,
        data: {
          ...state.data,
          ...action.payload.request.data,
        },
      };

    case 'PUT_SESSION_INTRA_SUCCESS':
      return {
        ...state,
        posting: false,
        posted: true,
      };

    case 'PUT_SESSION_INTRA_ERROR':
      return {
        ...state,
        posting: false,
        posted: true,
      };
    default:
      return state;
  }
};

const migrations = {
  0: (state) => {
    const { data } = state;
    return {
      ...state,
      data: {
        alternative_email: data?.externalEmail,
        azure_id: data?.microsoft_id,
        building: data?.building,
        city: data?.city,
        company: data?.company,
        contact_email: data?.email,
        contact_id: data?.userName,
        country: data?.country,
        created_date: null,
        data_protection_law: data?.dataProtectionLaw,
        description: data?.description,
        disabled_date: null,
        employment_type: data?.employment_type,
        first_name: data?.firstName,
        global_id: data?.globalUserName,
        id: null,
        is_disabled: data?.isDisabled,
        job_position: data?.job_position,
        key: null,
        keywords: data?.keywords,
        last_modified_by: null,
        last_modified_date: data?.lastModified,
        last_name: data?.lastName,
        manager_id: data?.manager,
        mobile_phone: data?.mobile_phone,
        my_mandatory_news_channels: data?.myMandatoryNewsChannels,
        nickname: data?.nickname,
        notifications: data?.notifications,
        organisation_acronym: data?.organisation_acronym,
        phone: data?.phone,
        preferred_local_intranet: data?.preferredLocalIntranetURL,
        reflex_cookie_date: data?.reflexCookieDate,
        reflex_terms_of_use_date: data?.reflexTermsofUseDate,
        rulesets: [],
        session_debug: data?.sesionDebug,
        statisticsEvents: data?.statisticsEvents,
        subscriptions: {
          my_reflex_channels: data?.myReflexChannels,
          my_email_channels: data?.myEmailChannels,
          my_reflex_sites: data?.myReflexSites,
        },
        target_groups: data?.targetGroups,
        thumbnail: data?.thumbnail,
        title: data?.title,
        url: data?.url,
        user_allowed_to_edit: data?.userAllowedToEdit,
        volkswagen_id: data?.volkswagen_id,
        xds_id: null,
        badges: data.badges ? data.badges.map((badge) => ({
          id: badge.id,
          badge_url: badge.badgeUrl || badge.badge_url,
          img_url: badge.imageUrl || badge.img_url,
        })) : [],
        ...data,
      },
    };
  },
};

const persistConfig = {
  key: 'session',
  storage: storageSession,
  whitelist: ['data', 'fetched', 'sessionID', 'avatar'],
  migrate: createMigrate(migrations, { debug: true }),
  stateReconciler: hardSet,
  version: 0,
};

export default persistReducer(persistConfig, session);
