import axios from 'axios';
import { sendStatistics } from './store/actions';

const getPageStatus = async () => {
  try {
    const url = window.location.href;
    const regex = /\?sv.url=([^&]+)&state=create#([^&]+)./g;
    const cleanUrl = url.replace(regex, '');

    const response = await axios.get(cleanUrl);
    return response && response.status;
  } catch (e) {
    return e && e.response && e.response.status;
  }
};

async function visitLogOutsideSPA(store) {
  // Visitlog for non SPA applications
  const path = window.location.pathname;
  let userName; // remove this when we have the new react profile in place on prod.

  if (path.indexOf('profile/') > -1) {
    [,, userName] = path.split('/');
  } else {
    userName = '';
  }

  const pageStatusCode = await getPageStatus();

  const data = { target: userName, type: 'visit', status_code: pageStatusCode?.toString() };

  if (store.getState().session.fetched) {
    store.dispatch(sendStatistics(data));
  } else {
    // session isnt fetched yet, wait until it is ready then dispatch statistics
    const unsubscribe = store.subscribe(() => {
      if (store.getState().session.fetched) {
        unsubscribe();
        store.dispatch(sendStatistics(data));
      }
    });
  }
}

export default visitLogOutsideSPA;
