export function fetchTypeahead(q) {
  return {
    type: 'TYPEAHEAD_FETCH',
    payload: {
      client: 'connect',
      request: {
        params: {
          q,
        },
        url: '/header/typeahead',
      },
    },
  };
}

export function requestTypeahead() {
  return {
    type: 'TYPEAHEAD_REQUEST',
  };
}
