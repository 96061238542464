export default function builder(containerBuilder) {
  // Register your top level component(s)
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactConnectSelectLanguage" */ './select-language')).as('ReactConnectSelectLanguage');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactConnectHeader" */ './connect-header')).as('ReactConnectHeader');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactConnectSpa" */ './connect-spa')).as('ReactConnectSpa');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactProfileCard" */ './common/profile-card')).as('ReactProfileCard');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactConnectDocumentCard" */ './document-card')).as('ReactConnectDocumentCard');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactConnectProfile" */ './connect-profile')).as('ReactConnectProfile');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactConnectDocumentList" */ './document-list')).as('ReactConnectDocumentList');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactConnectAlerts" */ './connect-alerts')).as('ReactConnectAlerts');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactPageFooter" */ './profile-footer')).as('ReactPageFooter');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactConnectDocumentCards" */ './document-cards')).as('ReactConnectDocumentCards');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactConnectTermsOfUse" */ './terms-of-use')).as('ReactConnecTermsOfUse');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactConnectDocumentCards" */ './news-promotion')).as('ReactNewsPromotion');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactNewsConfiguration" */ './common/news-list-configuration')).as('ReactNewsConfiguration');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactNewsList" */ './news-list')).as('ReactNewsList');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactConnectNewsArticleSubscribeBar" */ './news-article-subscribe-bar')).as('ReactConnectNewsArticleSubscribeBar');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "React404" */ './connect-spa/components/Error404')).as('React404');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactAccessTool" */ './access-tool')).as('ReactAccessTool');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactExpiringSession" */ './expiring-session')).as('ReactExpiringSession');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactCookie" */ './cookie')).as('ReactCookie');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactStarmark" */ './star-mark')).as('ReactStarmark');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactDashboard" */ './dashboard')).as('ReactDashboard');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactPopup" */ './popup-modal')).as('ReactPopupModal');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactVideoCard" */ './video-card')).as('ReactVideoCard');
  containerBuilder.registerAsync(() => import(/* webpackChunkName: "ReactPopupForm" */ './popup-form')).as('ReactPopupForm');
}
