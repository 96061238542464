const initialState = {
  data: {
    sitesAndApps: [],
    peopleAndOrgs: [],
    suggestedLinks: [],
  },
  fetching: false,
  fetched: false,
  error: null,
};

const typeahead = (state = initialState, action) => {
  switch (action.type) {
    case 'TYPEAHEAD_FETCH':
      return { ...state, fetching: true };

    case 'TYPEAHEAD_FETCH_SUCCESS':
      return {
        ...state, fetching: false, fetched: true, data: action.payload.data,
      };

    case 'TYPEAHEAD_FETCH_ERROR':
      return { ...state, fetching: false, error: action.error };

    default:
      return { ...state };
  }
};

export default typeahead;
