import { addLocaleData } from 'react-intl';

// Add new languages here
import en from 'react-intl/locale-data/en';
import sv from 'react-intl/locale-data/sv';

import { fetchLocale } from './store/actions';

// Add new languages here
export const availableLocales = [
  {
    name: 'English',
    lang: 'en',
    tag: 'en',
  },
  {
    name: 'Svenska',
    lang: 'sv',
    tag: 'sv',
  }];

const initLocales = (store) => new Promise((resolve) => {
  addLocaleData([...en, ...sv]); // Add new languages here

  // get browser locale. if that isnt available we use english
  let lang = navigator.userLanguage || navigator.language;
  if (lang.indexOf('-') !== -1) { [lang] = lang.split('-'); }
  const selectedLocale = availableLocales.some((l) => l.tag === lang)
    ? lang
    : availableLocales[0].tag;

  // Finally, dispatch an action to load the selected locales actual string data
  store.dispatch(fetchLocale(selectedLocale));

  // subscribe to store change that will trigger when the locale has been
  // fetched. when it is loaded we unsubscribe and resolve the promise
  // so that the react apps can initialize
  const unsubscribe = store.subscribe(() => {
    if (JSON.stringify(store.getState().intl.messages) !== '{}') {
      // locale has been loaded
      unsubscribe(); // remove this store subscriber
      resolve(); // resolve promise to allow apps to init
    }
  });
});

const getUserLocale = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  const userLang = availableLocales.find((language) => browserLanguage.startsWith(language.lang));

  // Return English if we have no support for the browser locale
  return userLang || availableLocales[0];
};

export {
  initLocales,
  getUserLocale,
};
