/**
 * Copyright 2016-present, Deloitte Digital.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 */

import React from 'react';
import { Provider } from 'react-intl-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createRoot } from 'react-dom/client';
import { AxiosProvider } from './axios';

/**
 * React Redux DOM Factory
 */
export default class ReduxDomFactory {
  constructor(store = null, persistor, axiosClients) {
    this.store = store;
    this.persistor = persistor;

    // we pass the created axios clients as a prop to all apps.
    // here we flatten the axiosClients object, because the apps
    // only care about the axios client not the options stuff.
    this.axiosClients = Object
      .keys(axiosClients)
      .reduce((accumulator, currentValue) => {
        accumulator[currentValue] = axiosClients[currentValue].client;
        return accumulator;
      }, {});
  }

  inject(module, props = {}, target) {
    if (target) {
      // add the axiosClients to the props
      const modifiedProps = { axios: this.axiosClients, ...props };

      // wrap the app in react-intl and redux-persist
      const root = createRoot(target);
      root.render(
        React.createElement(
          Provider, { store: this.store },
          React.createElement(
            PersistGate, { loading: null, persistor: this.persistor },
            React.createElement(AxiosProvider, { value: this.axiosClients },
              React.createElement(module, modifiedProps)),
          ),
        ),
      );
    } else {
      console.warn('Target element is null or undefined. Cannot inject component');
    }
  }
/* eslint-disable */
  dispose(target) {
    if (target) {
      ReactDom.unmountComponentAtNode(target);
    }
  }
}
/* eslint-enable */
