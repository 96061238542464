import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import session from './connect/session/reducers';
import headerLinks from './header/links/reducers';
import typeahead from './header/typeahead/reducers';
import headerPickup from './header/pickup/reducers';
import sites from './connect/sites/reducers';
import users from './users/reducers';
import userTeam from './users/team/reducers';
import searchAppsFull from './search/apps/reducers';
import searchPeopleFull from './search/people/reducers';
import folders from './folders/reducers';
import documents from './documents/reducers';
import alerts from './alerts/reducers';
import campaignComponents from './campaign-components/reducers';
import subscriptions from './subscriptions/reducers';

export default combineReducers({
  intl: intlReducer,
  session,
  headerLinks,
  typeahead,
  headerPickup,
  sites,
  users,
  userTeam,
  searchAppsFull,
  searchPeopleFull,
  folders,
  documents,
  alerts,
  campaignComponents,
  subscriptions,
});
