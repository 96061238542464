const initialState = {
  username: null,
  fetching: false,
  fetched: false,
  error: null,
  previousTeam: {},
};

const userTeamReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_TEAM_FULL_CLEAR':
      return {
        ...state, data: {}, fetched: false, fetching: false,
      };
    case 'USER_TEAM_FETCH':
      return {
        ...state,
        contact_id: action.contact_id,
        fetching: true,
        fetched: false,
      };
    case 'USER_TEAM_FETCH_SUCCESS':
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data,
        previousTeam: action.payload.data,
      };
    case 'USER_TEAM_FETCH_ERROR':
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: {
          message: action.error.message || action.error.data || 'error',
          statusCode: action.error.status
            || action.error.response ? action.error.response.status : 0,
        },
        data: state.previousTeam,
      };
    default:
      return state;
  }
};

export default userTeamReducers;
