export function fetchChannelSubscriptions() {
  return {
    type: 'SUBSCRIPTIONS_CHANNELS_FETCH',
    payload: {
      client: 'connect',
      request: {
        url: '/subscriptions/channels',
      },
    },
  };
}

export function getFavoriteSitesSubscriptions() {
  return {
    type: 'SUBSCRIPTIONS_SITES_FETCH',
    payload: {
      client: 'connect',
      request: {
        url: '/subscriptions/sites',
      },
    },
  };
}

export function deleteSubscriptions(data, noSuccessAlert = false) {
  return {
    type: 'SUBSCRIPTIONS_DELETE',
    noSuccessAlert,
    payload: {
      client: 'connect',
      request: {
        url: '/subscriptions',
        method: 'DELETE',
        data,
      },
    },
  };
}

export function postSubscriptions(data, noSuccessAlert = false) {
  return {
    type: 'SUBSCRIPTIONS_POST',
    noSuccessAlert,
    payload: {
      client: 'connect',
      request: {
        url: '/subscriptions',
        method: 'POST',
        data,
      },
    },
  };
}
