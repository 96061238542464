import {
  takeLeading, takeEvery, put, select,
} from 'redux-saga/effects';
import { fetchSession } from './actions';
import {
  addAlert, updatePreferredLocalIntranet, fetchUserTeam,
} from '../../actions';

function* sessionSaga() {
  const isFetched = yield select((state) => state.session.fetched);
  const isFetching = yield select((state) => state.session.fetching);
  if (!isFetched && !isFetching) {
    yield put(fetchSession());
  }
}

function* requestUserTeam() {
  const contact_id = yield select((state) => state.session.data.contact_id);
  const oldManager = yield select((state) => state.session.previousData.manager_id);
  const newManager = yield select((state) => state.session.data.manager_id);

  if (!newManager || !oldManager) {
    yield put(fetchUserTeam(contact_id.toLowerCase()));
  } else if (newManager.toLowerCase() !== oldManager.toLowerCase()) {
    yield put(fetchUserTeam(contact_id.toLowerCase()));
  }
}

function* showAlert(action) {
  let alert;
  switch (action.type) {
    case 'SESSION_PUT_SUCCESS':
      if (action.meta.previousAction.noSuccessAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/success/profile']),
        color: 'success',
      };
      break;
    case 'SESSION_PUT_ERROR':
      if (action.meta.previousAction.noErrorAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/error/profile']),
        color: 'failure',
      };
      break;
    case 'AVATAR_POST_SUCCESS':
      if (action.meta.previousAction.noSuccessAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/success/avatar']),
        color: 'success',
      };
      break;

    case 'AVATAR_POST_ERROR':
      if (action.meta.previousAction.noErrorAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/error/avatar']),
        color: 'failure',
      };
      break;

    case 'PUT_SESSION_INTRA_SUCCESS':
      if (action.meta.previousAction.noSuccessAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/success/profile']),
        color: 'success',
      };
      break;

    case 'PUT_SESSION_INTRA_ERROR':
      if (action.meta.previousAction.noErrorAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/error/profile']),
        color: 'failure',
      };
      break;

    case 'AVATAR_DELETE_SUCCESS':
      if (action.meta.previousAction.noSuccessAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/success/avatar']),
        color: 'success',
      };
      break;

    case 'AVATAR_DELETE_ERROR':
      if (action.meta.previousAction.noErrorAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/error/avatar']),
        color: 'failure',
      };
      break;

    default:
      break;
  }

  if (alert) {
    yield put(addAlert(alert));
  }
}

function* intraSessionPutSuccess(action) {
  yield put(updatePreferredLocalIntranet(action.meta.previousAction));
}

const sessionSagas = [
  takeLeading('SESSION_REQUEST', sessionSaga),
  takeEvery('SESSION_PUT_SUCCESS', requestUserTeam),
  takeEvery(['AVATAR_POST_ERROR', 'SESSION_PUT_SUCCESS', 'AVATAR_POST_SUCCESS', 'SESSION_PUT_ERROR', 'PUT_SESSION_INTRA_SUCCESS', 'PUT_SESSION_INTRA_ERROR', 'AVATAR_DELETE_SUCCESS', 'AVATAR_DELETE_ERROR'], showAlert),
  takeEvery('PUT_SESSION_INTRA_SUCCESS', intraSessionPutSuccess),
];

export default sessionSagas;
