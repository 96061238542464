import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
// For IE 11 support
import './polyfills';

import objectFitImages from 'object-fit-images';
import ReactHabitat from 'react-habitat';
import { defineCustomElements } from '@scania/tegel/loader';
import authenticate from './Auth';
import createAxios from './axios';
import createStore from './store/store';
import ReduxDomFactory from './ReduxDomFactory';
import { initLocales } from './locales';
import registerApps from './apps';
import visitLog from './visitLog';
import awsRum from './awsRum';
import { requestSession } from './store/actions';

import './scss/main.scss';

const setVh = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

function addAlertsToDOM() {
  // In order for all apps to be able to send alerts, the ReactConnectAlerts app need to be present
  // so we always add this to the DOM
  const element = document.createElement('div');
  element.setAttribute('data-component', 'ReactConnectAlerts');
  document.body.appendChild(element);
}

function addExpiringSession() {
  // In order for all apps to be able to send alerts, the ReactConnectAlerts app need to be present
  // so we always add this to the DOM
  const element = document.createElement('div');
  element.setAttribute('data-component', 'ReactExpiringSession');
  document.body.appendChild(element);
}

function addTermsToDOM() {
  // ReactConnecTermsOfUse app need to always be present so we add this to the DOM
  const element = document.createElement('div');
  element.setAttribute('data-component', 'ReactConnecTermsOfUse');
  document.body.appendChild(element);
}

function addCookieToDOM() {
  // In order for all apps to be able to send alerts, the ReactConnectAlerts app need to be present
  // so we always add this to the DOM
  const element = document.createElement('div');
  element.setAttribute('data-component', 'ReactCookie');
  document.body.appendChild(element);
}

function addVhFix() {
  window.addEventListener('resize', setVh);
  setVh();
}

document.addEventListener('DOMContentLoaded', () => {
  objectFitImages();
});

class App extends ReactHabitat.Bootstrapper {
  constructor() {
    super();
    this.className = 'reflex sdds-mode-light';

    window.updateHabitat = this.update.bind(this);

    authenticate()
      .then(this.signedIn.bind(this))
      .catch((error) => console.log(error));
  }

  signedIn(session) {
    // create axios clients for different api endpoints (mock, connect, cdn, etc..)
    // These can be accessed through the store or directly in the apps through the axios prop
    const axiosClients = createAxios(session);

    // create store and load locale
    const { store, persistor } = createStore(axiosClients, this.rehydrationComplete.bind(this));
    this.store = store;

    // react-habitat stuff
    this.containerBuilder = new ReactHabitat.ContainerBuilder({ className: this.className });
    this.containerBuilder.factory = new ReduxDomFactory(this.store, persistor, axiosClients);

    // fetch locale and then create react apps
    initLocales(store)
      .then(this.initApps.bind(this));
  }

  initApps() {
    // locale is loaded, initialize react apps

    // add connect-alerts, expiring-session, cookie, terms and objectFitImages to the DOM
    objectFitImages();
    addExpiringSession();
    addAlertsToDOM();
    addCookieToDOM();
    addTermsToDOM();
    addVhFix();
    registerApps(this.containerBuilder);
    this.setContainer(this.containerBuilder.build());
    defineCustomElements();
    awsRum();
  }

  localeError(error) {
    console.log(this, error);
  }

  rehydrationComplete() {
    // always request session, as it is so ubiquitously used throughout the apps.
    // that means apps can always assume Session is available or fetching and don't
    // need to request it themselves
    this.store.dispatch(requestSession());

    if (window.location.origin !== process.env.REACT_APP_SPA && window.location.origin !== 'https://localhost.scania.com:3000') {
      // if not SPA (Visitlog for SPA is handle in connect-spa/components/Visitlog)
      visitLog(this.store);
    }
  }
}

// Always export a 'new' instance so it immediately evokes
export default new App();
