import {
  takeLeading, takeEvery, put, select,
} from 'redux-saga/effects';
import { fetchHeaderLinks } from './actions';
import { addAlert } from '../../actions';

function* headerLinksSaga() {
  const isFetched = yield select((state) => state.headerLinks.fetched);
  const isFetching = yield select((state) => state.headerLinks.fetching);
  const lang = navigator.language || navigator.userLanguage;
  if (!isFetched && !isFetching) {
    yield put(fetchHeaderLinks(lang));
  }
}

function* showAlert(action) {
  let alert;
  switch (action.type) {
    case 'HEADERLINKS_POST_SUCCESS':
      if (action.meta.previousAction.noSuccessAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/success/profile']),
        color: 'success',
      };
      break;
    case 'HEADERLINKS_POST_ERROR':
      if (action.meta.previousAction.noErrorAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/error/profile']),
        color: 'failure',
      };
      break;
    case 'HEADERLINKS_DELETE_SUCCESS':
      if (action.meta.previousAction.noSuccessAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/success/profile']),
        color: 'success',
      };
      break;
    case 'HEADERLINKS_DELETE_ERROR':
      if (action.meta.previousAction.noErrorAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/error/profile']),
        color: 'failure',
      };
      break;
    default:
      break;
  }
  if (alert) {
    yield put(addAlert(alert));
  }
}

const headerLinksSagas = [
  takeLeading('HEADERLINKS_REQUEST', headerLinksSaga),
  takeEvery(['HEADERLINKS_POST_SUCCESS', 'HEADERLINKS_POST_ERROR', 'HEADERLINKS_DELETE_SUCCESS', 'HEADERLINKS_DELETE_ERROR'], showAlert),
];

export default headerLinksSagas;
