import { takeLeading, put, select } from 'redux-saga/effects';
import { fetchUser } from './actions';

function* userSaga(action) {
  const user = yield select((state) => state.users.users.find((u) => (
    u.contact_id.toLowerCase() === action.contact_id.toLowerCase()
  )));

  if (!user || (!user.fetching && !user.fetched)) {
    yield put(fetchUser(action.contact_id));
  }
}

const userSagas = [
  takeLeading('USER_REQUEST', userSaga),
];

export default userSagas;
