export function fetchHeaderLinks(lang) {
  return {
    type: 'HEADERLINKS_FETCH',
    payload: {
      client: 'connect',
      request: {
        params: {
          lang,
        },
        url: '/header/links',
      },
    },
  };
}

export function requestHeaderLinks() {
  return {
    type: 'HEADERLINKS_REQUEST',
  };
}

export function postHeaderLinks(data) {
  return {
    type: 'HEADERLINKS_POST',
    noSuccessAlert: data.noSuccessAlert,
    payload: {
      client: 'connect',
      request: {
        url: '/header/links',
        method: 'POST',
        data,
      },
    },
  };
}

export function deleteHeaderLinks(data) {
  return {
    type: 'HEADERLINKS_DELETE',
    payload: {
      client: 'connect',
      request: {
        url: `/header/links/${data}`,
        method: 'DELETE',
      },
    },
  };
}

export function updatePreferredLocalIntranet(payload) {
  return {
    type: 'HEADERLINKS_UPDATE_PREFERREDLOCALINTRANET',
    payload,
  };
}
export function updateFavoriteSites(payload) {
  return {
    type: 'HEADERLINKS_UPDATE_FAVORITESITES',
    payload,
  };
}
