import {
  Logger, Hub, Amplify, Auth,
} from 'aws-amplify';
import 'url-search-params-polyfill';
import {
  CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUserSession,
} from 'amazon-cognito-identity-js';

// Logger.LOG_LEVEL = 'DEBUG';

const config = {
  storage: window.sessionStorage,
  Analytics: {
    disabled: true,
  },
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    mandatorySignIn: true,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOL_WEBCLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
      scope: process.env.REACT_APP_COGNITO_OAUTH_SCOPE.split(','),
      redirectSignIn: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_URI_SIGNIN,
      redirectSignOut: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_URI_SIGNOUT,
      responseType: 'code',
      options: {
        AdvancedSecurityDataCollectionFlag: false,
      },
    },
  },
};

const createSession = (data) => {
  const idToken = new CognitoIdToken({ IdToken: data.idToken });
  const accessToken = new CognitoAccessToken({ AccessToken: data.accessToken });
  const refreshToken = new CognitoRefreshToken({ RefreshToken: data.refreshToken });

  const session = new CognitoUserSession({
    IdToken: idToken,
    AccessToken: accessToken,
    RefreshToken: refreshToken,
  });

  return session;
};

function redirectToLogin(error, resolve) {
  const clientId = config.Auth.userPoolWebClientId;
  const { domain, redirectSignIn, responseType } = config.Auth.oauth;

  // check for ?code parameter in url - return if it exists
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('code') && error !== 'signIn_failure') {
    console.log(error);
    // eslint-disable-next-line no-underscore-dangle
    Auth._oAuthHandler.handleAuthResponse(`https://${domain}?code=${urlParams.get('code')}`)
      .then((res) => {
        // Clear code from URL
        urlParams.delete('code');
        window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);

        // Create Session data
        const session = createSession(res);

        Auth.Credentials.set(session, 'session');

        const currentUser = Auth.createCognitoUser(session.getIdToken().decodePayload()['cognito:username']);
        currentUser.setSignInUserSession(session);

        resolve(session);
      });
    return;
  }

  const currentUrl = encodeURIComponent(window.location.href);
  const url = `https://${domain}/oauth2/authorize?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${clientId}&identity_provider=${process.env.REACT_APP_COGNITO_SAML_PROVIDER_NAME}&state=${currentUrl}`;

  // finally, redirect
  window.location.assign(url);
}

function success(resolve) {
  // we are logged in, return the session
  Amplify.Auth.currentSession()
    .then(resolve)
    .catch(redirectToLogin);

  // clear code from url
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('code')) {
    urlParams.delete('code');
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
  }
}

function authenticate() {
  return new Promise((resolve) => {
  // we are authenticated when either the Hub logger gets the 'signIn' event or Amplify.Auth has a
  // authenticated user
  // set up the Hub event logger
    const logger = new Logger('logger');

    Hub.listen('auth', ({ payload: { event = undefined } }) => {
      switch (event) {
        case 'signIn':
          success(resolve);
          break;
        case 'signIn_failure':
          logger.info('User sign in failed');
          break;
        default:
          logger.info(`Event that we're not subscribing to. Event: ${event}`);
      }
    });

    Amplify.configure(config);

    Amplify.Auth.currentAuthenticatedUser() // check login status
      .then(() => success(resolve))
      .catch((message) => redirectToLogin(message, resolve));
  });
}

export default authenticate;
