export function fetchHeaderPickup() {
  return {
    type: 'HEADERPICKUP_FETCH',
    payload: {
      client: 'connect',
      request: {
        url: '/header/pickup',
      },
    },
  };
}

export function requestHeaderPickup() {
  return {
    type: 'HEADERPICKUP_REQUEST',
  };
}
