const initialState = {
  data: {
    description: '',
    name: '',
    url: '',
  },
  fetching: false,
  fetched: false,
  error: null,
};

const sites = (state = initialState, action) => {
  switch (action.type) {
    case 'SITES_FETCH':
      return { ...state, fetching: true };

    case 'SITES_FETCH_SUCCESS':
      return {
        ...state, fetching: false, fetched: true, data: action.payload.data,
      };

    case 'SITES_FETCH_ERROR':
      return { ...state, fetching: false, error: action.error };

    default:
      return { ...state };
  }
};

export default sites;
