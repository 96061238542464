export function postStatistics(data) {
  return {
    type: 'STATISTICS_POST',
    payload: {
      client: 'connect',
      request: {
        url: `/statistics/${data.type}`,
        method: 'POST',
        data,
      },
    },
  };
}

export function sendStatistics(data) {
  return {
    type: 'STATISTICS_SEND',
    data,
  };
}
