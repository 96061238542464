export function fetchLocale(locale) {
  return {
    type: 'LOCALE_FETCH',
    payload: {
      client: 'cdn',
      id: locale,
      request: {
        url: `/react/assets/locale/${locale}.json`,
      },
    },
  };
}

export const errorLocale = (error) => ({
  type: 'LOCALE_FETCH_ERROR',
  error,
});
