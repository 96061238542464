export function fetchUser(contact_id) {
  return {
    type: 'USER_FETCH',
    contact_id,
    payload: {
      client: 'connect',
      request: {
        url: `/users/${contact_id}`,
      },
    },
  };
}

export function requestUser(contact_id) {
  return {
    type: 'USER_REQUEST',
    contact_id,
  };
}

export function fetchUserNew(username) {
  return {
    type: 'USER_FETCH',
    username,
    payload: {
      client: 'connect2',
      request: {
        url: `/users/${username}`,
      },
    },
  };
}
