import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer, createMigrate } from 'redux-persist';
import hardSet from 'redux-persist/es/stateReconciler/hardSet';

const initialState = {
  users: [],
};

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_REQUEST':
      return { ...state };
    case 'USER_FETCH':
      if (state.users.some((user) => user.contact_id === action.contact_id)) {
        return { ...state };
      }
      return {
        ...state,
        users: state.users.concat([{
          contact_id: action.contact_id,
          fetching: true,
          fetched: false,
          data: {},
        }]),
      };
    case 'USER_FETCH_SUCCESS':
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.contact_id === action.meta.previousAction.contact_id) {
            return {
              ...user,
              fetching: false,
              fetched: true,
              data: action.payload.data,
            };
          }
          return user;
        }),
      };
    case 'USER_FETCH_ERROR':
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.contact_id === action.meta.previousAction.contact_id) {
            return {
              ...user,
              fetching: false,
              fetched: false,
              error: {
                message: action.error.message || action.error.data || 'error',
                statusCode: action.error.status
                  || action.error.response ? action.error.response.status : 0,
              },
            };
          }
          return user;
        }),
      };
    default:
      return state;
  }
};

const migrations = {
  0: (state) => ({
    ...state,
    users: state.users.map(({
      username, fetching, fetched, data,
    }) => ({
      contact_id: username,
      fetching,
      fetched,
      data: {
        alternative_email: data?.externalEmail,
        azure_id: data?.microsoft_id,
        building: data?.building,
        city: data?.city,
        company: data?.company,
        contact_email: data?.email,
        contact_id: data?.userName,
        country: data?.country,
        created_date: null,
        data_protection_law: data?.dataProtectionLaw,
        description: data?.description,
        disabled_date: null,
        employment_type: data?.employment_type,
        first_name: data?.firstName,
        global_id: data?.globalUserName,
        id: null,
        information_type: data?.information_type,
        is_disabled: data?.isDisabled,
        job_position: data?.job_position,
        key: null,
        keywords: data?.keywords,
        last_modified_by: null,
        last_modified_date: data?.lastModified,
        last_name: data?.lastName,
        manager_id: data?.manager,
        mobile_phone: data?.mobile_phone,
        nickname: data?.nickname,
        organisation_acronym: data?.organisation_acronym,
        phone: data?.phone,
        preferred_local_intranet: data?.preferredLocalIntranetURL,
        reflex_cookie_date: data?.reflexCookieDate,
        reflex_terms_of_use_date: data?.reflexTermsofUseDate,
        rulesets: [],
        statisticsEvent: data?.statisticsEvent,
        target_groups: data?.targetGroups,
        thumbnail: data?.thumbnail,
        title: data?.title,
        url: data?.url,
        user_allowed_to_edit: data?.userAllowedToEdit,
        volkswagen_id: data?.volkswagen_id,
        xds_id: null,
        badges: data.badges ? data.badges.map((badge) => ({
          id: badge.id,
          badge_url: badge.badgeUrl || badge.badge_url,
          img_url: badge.imageUrl || badge.img_url,
        })) : [],
      },
    })),
  }),
};

const persistConfig = {
  key: 'users',
  storage: storageSession,
  migrate: createMigrate(migrations, { debug: true }),
  stateReconciler: hardSet,
  version: 0,
};

export default persistReducer(persistConfig, userReducers);
