export function fetchSites(siteId) {
  return {
    type: 'SITES_FETCH',
    payload: {
      client: 'connect',
      request: {
        url: `/sites/${siteId}`,
      },
    },
  };
}

export function requestSites() {
  return {
    type: 'SITES_REQUEST',
  };
}
