const initialState = {
  metadata: {},
  documents: [],
  fetching: false,
  fetched: false,
  error: null,
};

const searchPeople = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_PEOPLE_FULL_CLEAR':
      return {
        ...state, metadata: {}, documents: [], fetched: false,
      };

    case 'SEARCH_PEOPLE_FULL_FETCH':
      return { ...state, fetching: true };

    case 'SEARCH_PEOPLE_FULL_FETCH_SUCCESS':
      return {
        ...state,
        fetching: false,
        fetched: true,
        metadata: action.payload.data,
        documents: [...state.documents, ...action.payload.data.documents],
      };

    case 'SEARCH_PEOPLE_FULL_FETCH_ERROR':
      return { ...state, fetching: false, error: action.error };

    default:
      return { ...state };
  }
};

export default searchPeople;
