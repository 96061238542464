import { takeLeading, put, select } from 'redux-saga/effects';
import { fetchSites } from './actions';

function* sitesSaga() {
  const isFetched = yield select((state) => state.sites.fetched);
  const isFetching = yield select((state) => state.sites.fetching);
  if (!isFetched && !isFetching) {
    yield put(fetchSites());
  }
}

const sitesSagas = [
  takeLeading('SITES_REQUEST', sitesSaga),
];

export default sitesSagas;
