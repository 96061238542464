const initialState = [];

const documentReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'DOCUMENT_FETCH':
      return [
        ...state,
        {
          documentId: action.documentId,
          fetching: true,
          fetched: false,
          data: {},
        },
      ];

    case 'DOCUMENT_FETCH_SUCCESS':
      return state.map((document) => {
        if (document.documentId === action.meta.previousAction.documentId) {
          return {
            ...document,
            fetching: false,
            fetched: true,
            data: action.payload.data,
          };
        }
        return document;
      });

    case 'DOCUMENT_FETCH_ERROR':
      return state.map((document) => {
        if (document.documentId === action.meta.previousAction.documentId) {
          return {
            ...document,
            fetching: false,
            fetched: false,
            error: {
              message: action.error.message,
              statusCode: action.error.response.status,
            },
          };
        }
        return document;
      });
    default:
      return state;
  }
};

export default documentReducers;
