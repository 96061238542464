export function fetchCampaignComponents(lang) {
  return {
    lang,
    type: 'CAMPAIGN_COMPONENTS_FETCH',
    payload: {
      client: 'backendCDN',
      request: {
        url: `/campaign/${lang}.json`,
      },
    },
  };
}

export function requestCampaignComponents(lang) {
  return {
    type: 'CAMPAIGN_COMPONENTS_REQUEST',
    lang,
  };
}
