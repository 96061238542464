export function fetchSession() {
  return {
    type: 'SESSION_FETCH',
    payload: {
      client: 'connect',
      request: {
        url: '/session',
      },
    },
  };
}

export function putSession(data, waitForRecache) {
  const params = {};
  if (waitForRecache) { params.waitForRecache = true; }
  return {
    type: 'SESSION_PUT',
    noSuccessAlert: data.noSuccessAlert,
    payload: {
      client: 'connect',
      request: {
        url: '/session',
        params,
        method: 'PUT',
        data,
      },
    },
  };
}
export function requestSession() {
  return {
    type: 'SESSION_REQUEST',
  };
}

export function postAvatar(config, avatar) {
  return {
    type: 'AVATAR_POST',
    noSuccessAlert: config.noSuccessAlert,
    payload: {
      client: 'connect',
      request: {
        url: '/session/avatar',
        method: 'POST',
        data: config.data,
      },
    },
    avatar,
  };
}

export function deleteAvatar() {
  return {
    type: 'AVATAR_DELETE',
    payload: {
      client: 'connect',
      request: {
        url: '/session/avatar',
        method: 'DELETE',
      },
    },
  };
}

export function putSessionIntra(selectIntranet) {
  return {
    type: 'PUT_SESSION_INTRA',
    preferredLocalIntranet: selectIntranet,
    payload: {
      client: 'connect',
      request: {
        url: '/session',
        method: 'PUT',
        data: {
          preferred_local_intranet: selectIntranet.site_id,
        },
      },
    },
  };
}
