import { takeLeading, select, put } from 'redux-saga/effects';
import { fetchCampaignComponents } from './actions';

function* campaignComponentsRequestSaga(action) {
  const ttl = 15 * 60 * 1000; // 15 minutes

  const timestamp = yield select((state) => state.campaignComponents.timestamp);
  const isFetched = yield select((state) => state.campaignComponents.fetched);
  const isFetching = yield select((state) => state.campaignComponents.fetching);

  if ((!isFetched && !isFetching)
  || (isFetched && !isFetching && (+new Date() - timestamp) > ttl)) {
    // if millisec since fetched is bigger then ttl
    yield put(fetchCampaignComponents(action.lang));
  }
}

const campaignComponentsSagas = [
  takeLeading('CAMPAIGN_COMPONENTS_REQUEST', campaignComponentsRequestSaga),
];

export default campaignComponentsSagas;
