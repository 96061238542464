import { takeLeading, put, select } from 'redux-saga/effects';
import { fetchHeaderPickup } from './actions';

function* headerPickupSaga() {
  const isFetched = yield select((state) => state.headerPickup.fetched);
  const isFetching = yield select((state) => state.headerPickup.fetching);
  if (!isFetched && !isFetching) {
    yield put(fetchHeaderPickup());
  }
}

const headerPickupSagas = [
  takeLeading('HEADERPICKUP_REQUEST', headerPickupSaga),
];

export default headerPickupSagas;
