import {
  takeEvery, put, delay,
} from 'redux-saga/effects';
import { removeAlert } from './actions';

function* alertSaga(action) {
  yield delay(5000);
  yield put(removeAlert(action.alert.key));
}

const sessionSagas = [
  takeEvery('ALERT_ADD', alertSaga),
];

export default sessionSagas;
