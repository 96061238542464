import { takeEvery, put } from 'redux-saga/effects';
import { updateIntl } from 'react-intl-redux';

function* localeFetchSuccess(action) {
  yield put(updateIntl({
    locale: action.meta.previousAction.payload.id,
    messages: action.payload.data,
  }));
}

const localeSagas = [
  takeEvery('LOCALE_FETCH_SUCCESS', localeFetchSuccess),
];

export default localeSagas;
