import { all } from 'redux-saga/effects';
import localeSagas from './locale/sagas';
import sessionSagas from './connect/session/sagas';
import headerLinksSagas from './header/links/sagas';
import TypeaheadSagas from './header/typeahead/sagas';
import headerPickupSaga from './header/pickup/sagas';
import sitesSaga from './connect/sites/sagas';
import userSagas from './users/sagas';
import statisticsSagas from './statistics/sagas';
import alertSagas from './alerts/sagas';
import campaignComponentsSagas from './campaign-components/sagas';
import subscriptionsSagas from './subscriptions/sagas';

export default function* rootSaga() {
  yield all([
    ...localeSagas,
    ...sessionSagas,
    ...headerLinksSagas,
    ...TypeaheadSagas,
    ...headerPickupSaga,
    ...sitesSaga,
    ...userSagas,
    ...statisticsSagas,
    ...alertSagas,
    ...campaignComponentsSagas,
    ...subscriptionsSagas,
  ]);
}
