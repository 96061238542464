import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { sendStatistics } from './actions';

import rootReducer from './reducers';
import rootSaga from './sagas';

function generateInteractionPartFromUrl(url) {
  const urlParts = url.split('/');
  for (let i = 0; i < urlParts.length; i += 1) {
    if (urlParts[i] === 'v1' || urlParts[i] === 'cognito') {
      const interactionPart = `API_${urlParts[i + 1]}`.toUpperCase();
      return interactionPart;
    }
  }
  return 'API_UNKNOWN';
}

function makeStore(axiosClients, callback) {
  const sagaMiddleware = createSagaMiddleware();
  const axiosMiddlewareOptions = {
    returnRejectedPromiseOnError: true,
    interceptors: {
      response: [
        {
          error: ({ dispatch }, error) => {
            // Do not send errors if the statistics API i not responding correctly,
            // Otherwise we will get an infinite loop.
            if (error.config && error.config.url && error.config.url.indexOf('statistics') === -1) {
              const statistics = {
                interaction_part: generateInteractionPartFromUrl(error.config.url),
                event_type: error.config.url,
                type: 'usage',
                status_code: error.response.status?.toString(),
              };

              dispatch(sendStatistics(statistics));
            }
            return Promise.reject(error);
          },
        },
      ],
    },
  };
  const axiosMiddleware = multiClientMiddleware(axiosClients, axiosMiddlewareOptions);
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; /* eslint-disable-line */

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(axiosMiddleware, sagaMiddleware)),
  );

  const persistor = persistStore(store, null, callback);
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
}

export default makeStore;
