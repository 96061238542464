const initialState = {
  data: {
    my_reflex_channels: [],
    my_email_channels: [],
    my_mandatory_news_channels: [],
    my_reflex_sites: [],
  },
  fetching: false,
  fetched: false,
  error: null,
};

const channels = (state = initialState, action) => {
  switch (action.type) {
    case 'SUBSCRIPTIONS_CHANNELS_FETCH':
    case 'SUBSCRIPTIONS_SITES_FETCH':
    case 'SUBSCRIPTIONS_DELETE':
    case 'SUBSCRIPTIONS_POST':
      return { ...state, fetching: true };

    case 'SUBSCRIPTIONS_CHANNELS_FETCH_SUCCESS':
    case 'SUBSCRIPTIONS_SITES_FETCH_SUCCESS':
    case 'SUBSCRIPTIONS_DELETE_SUCCESS':
    case 'SUBSCRIPTIONS_POST_SUCCESS':
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data,
      };

    case 'SUBSCRIPTIONS_CHANNELS_FETCH_ERROR':
    case 'SUBSCRIPTIONS_SITES_FETCH_ERROR':
    case 'SUBSCRIPTIONS_DELETE_ERROR':
    case 'SUBSCRIPTIONS_POST_ERROR':
      return {
        ...state, fetching: false, fetched: true, error: action.error,
      };

    default:
      return { ...state };
  }
};

export default channels;
