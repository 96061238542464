import shortid from 'shortid';

export function addAlert(alert) {
  return {
    type: 'ALERT_ADD',
    alert: {
      message: alert.message || '',
      color: alert.color || 'primary',
      key: shortid.generate(),
      action: alert.action || null,
    },
  };
}

export function removeAlert(key) {
  return {
    type: 'ALERT_REMOVE',
    key,
  };
}
