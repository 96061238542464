import axios from 'axios';
import React from 'react';

export default function axiosMethods(session) {
  return {
    default: {
      client: axios.create(),
    },
    mock: {
      client: axios.create({
        baseURL: process.env.REACT_APP_MOCK_API,

      }),
      options: {
        errorSuffix: '_ERROR',
      },
    },
    cdn: {
      client: axios.create({
        baseURL: process.env.REACT_APP_AWS_S3_URL,

      }),
      options: {
        errorSuffix: '_ERROR',
      },
    },
    backendCDN: {
      client: axios.create({
        baseURL: process.env.REACT_APP_CDN_URL,
      }),
      options: {
        errorSuffix: '_ERROR',
      },
    },
    connect: {
      client: axios.create({
        baseURL: process.env.REACT_APP_CONNECT_API,
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      }),
      options: {
        errorSuffix: '_ERROR',
      },
    },
    graph: {
      client: axios.create({
        baseURL: process.env.REACT_APP_GRAPH_API,
        headers: {
          authorization: session.idToken.jwtToken,
        },
      }),
      options: {
        errorSuffix: '_ERROR',
      },
    },
    connect2: {
      client: axios.create({
        baseURL: process.env.REACT_APP_CONNECT_API_NEW,
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      }),
      options: {
        errorSuffix: '_ERROR',
      },
    },
    base: {
      client: axios.create({
        baseURL: process.env.REACT_APP_CONNECT_API_BASE,
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      }),
      options: {
        errorSuffix: '_ERROR',
      },
    },
  };
}

// Make the above axios instance available as a HOC
// See src/apps/dev/with-axios-example for example usage

const AxiosContext = React.createContext({});
export const AxiosProvider = AxiosContext.Provider;
export const AxiosConsumer = AxiosContext.Consumer;

export function withAxios(Component) {
  return function WrapperComponent(props) {
    return (
      <AxiosConsumer>
        {(axiosInstance) => <Component {...props} axios={axiosInstance} />}
      </AxiosConsumer>
    );
  };
}
