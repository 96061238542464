export function fetchUserTeam(contact_id) {
  return {
    type: 'USER_TEAM_FETCH',
    contact_id,
    payload: {
      client: 'connect',
      request: {
        url: `/users/${contact_id}/team`,
      },
    },
  };
}

export function fetchUserTeamNew(username) {
  return {
    type: 'USER_TEAM_FETCH',
    username,
    payload: {
      client: 'connect2',
      request: {
        url: `/users/${username}/team`,
      },
    },
  };
}

export function clearUserTeam() {
  return {
    type: 'USER_TEAM_FULL_CLEAR',
  };
}

export function requestUserTeam(contact_id) {
  return {
    type: 'USER_TEAM_REQUEST',
    contact_id,
  };
}
