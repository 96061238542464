import {
  takeEvery, put, select,
} from 'redux-saga/effects';
import {
  addAlert,
} from '../actions';

function* showAlert(action) {
  let alert;
  switch (action.type) {
    case 'SUBSCRIPTIONS_DELETE_SUCCESS':
    case 'SUBSCRIPTIONS_POST_SUCCESS':
      if (action.meta.previousAction.noSuccessAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/success/profile']),
        color: 'success',
      };
      break;
    case 'SUBSCRIPTIONS_FETCH_ERROR':
    case 'SUBSCRIPTIONS_DELETE_ERROR':
    case 'SUBSCRIPTIONS_POST_ERROR':
      if (action.meta.previousAction.noErrorAlert) return;
      alert = {
        message: yield select((state) => state.intl.messages['generic/error/profile']),
        color: 'failure',
      };
      break;

    default:
      break;
  }

  if (alert) {
    yield put(addAlert(alert));
  }
}

const sessionSagas = [
  takeEvery([
    'SUBSCRIPTIONS_DELETE_SUCCESS',
    'SUBSCRIPTIONS_POST_SUCCESS',
    'SUBSCRIPTIONS_FETCH_ERROR',
    'SUBSCRIPTIONS_DELETE_ERROR',
    'SUBSCRIPTIONS_POST_ERROR',
  ], showAlert),
];

export default sessionSagas;
