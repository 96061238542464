const initialState = [];

const alertsReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'ALERT_ADD':
      return [
        action.alert,
        ...state,
      ];
    case 'ALERT_REMOVE':
      return state.filter((alert) => alert.key !== action.key);
    default:
      return state;
  }
};

export default alertsReducers;
